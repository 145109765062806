import * as Yup from "yup";

import FormikCheckBox from "components/formik/FormikCheckBox";
import FormikDateInput from "components/formik/FormikDateInput";
import FormikFileUpload from "components/formik/FormikImageUpload";
import FormikPhoneInput from "components/formik/FormikPhoneInput";
import FormikRadioInput from "components/formik/FormikRadioInput";
import FormikStateInput from "components/formik/FormikStateInput";
import FormikTextArea from "components/formik/FormikTextArea";
import FormikTextInput from "components/formik/FormikTextInput";
import FormikTrueFalse from "components/formik/FormikTrueFalse";
import {
  FileBase64,
  FileBase64Error,
  FileFormatError,
} from "components/labeledInput/LabeledImageInput";
import backend from "services/backend";
import QuestionnaireSubmitted from "./QuestionnaireSubmitted";
import QuestionnaireSummary from "./QuestionnaireSummary";

export const fieldId = {
  idImageFile: "idImageFile",
  selfieImageFile: "selfieImageFile",
  dateOfBirth: "dateOfBirth",
  email: "email",
  firstName: "firstName",
  lastName: "lastName",
  phone: "phone",
  sex: "sex",
  diabetic: "diabetic",
  medicalConditions: "medicalConditions",
  medicalConditionsList: "medicalConditionsList",
  prescriptions: "prescriptions",
  prescriptionsList: "prescriptionsList",
  allergicAdhesive: "allergicAdhesive",
  allergicOther: "allergicOther",
  allergicOtherList: "allergicOtherList",
  insulin: "insulin",
  highA1C: "highA1C",
  fastingGlc: "fastingGlc",
  termsOfUse: "termsOfUse",
  state: "state",
  streetAddress1: "streetAddress1",
  streetAddress2: "streetAddress2",
  city: "city",
  shippingZip: "shippingZip",
  homeZip: "homeZip",
} as const;

export type QuestionFieldId = typeof fieldId[keyof typeof fieldId];
export type QuestionFields = { [k in QuestionFieldId]: any };

export type QuestionContent = {
  component: React.ReactNode;
  initialValues: Partial<QuestionFields>;
  schema: Yup.ObjectSchema<Partial<QuestionFields>> | null; // YUP schema
};

// Around Sep 23 Truepill found out via a legal audit that in
// [Delaware, District of Columbia, Kansas, Kentucky, Mississippi, Missouri or New Mexico]
// TP/we will need to provide sync-consults, so we can't just do the questionnaire.
// In the meantime, to prevent rejected prescriptions we forbid filling the questionnaire in these states.
const TEMPORARILY_UNSUPPORTED_STATES = [
  "DE",
  "DC",
  "KS",
  "KY",
  "MS",
  "MO",
  "NM",
];

export const SUPPORTED_FILE_TYPES = ["image/png", "image/jpeg", "image/jpg"];

export const contentLabelMap = new Map<QuestionFieldId, string>([
  [fieldId.termsOfUse, "I have read and agree to the Privacy Policy"],
  [fieldId.firstName, "First Name"],
  [fieldId.lastName, "Last Name"],
  [fieldId.phone, "Phone Number"],
  [fieldId.email, "Email"],
  [fieldId.dateOfBirth, "Date of Birth"],
  [fieldId.state, "State"],
  [fieldId.streetAddress1, "Street Address 1"],
  [fieldId.streetAddress2, "Street Address 2"],
  [fieldId.city, "City"],
  [fieldId.shippingZip, "ZIP Code (Shipping)"],
  [fieldId.homeZip, "ZIP Code (Home)"],
  [fieldId.sex, "What is your biological sex?"],
  [
    fieldId.allergicAdhesive,
    "Do you have an allergy to medical grade adhesives?",
  ],
  [
    fieldId.diabetic,
    "Do you currently have a diagnosis of Type 1 or Type 2 diabetic?",
  ],
  [
    fieldId.insulin,
    "Are you currently taking insulin or any injectable/oral diabetic medications?",
  ],
  [
    fieldId.highA1C,
    "In the past 6 months have you received a Glycosylated Hemoglobin (A1C) test result of 6.5% or higher?",
  ],
  [
    fieldId.fastingGlc,
    "In the past 6 months have you received a fasting blood glucose result above 126?",
  ],
  [fieldId.prescriptions, "I don’t take any medications."],
  [
    fieldId.allergicOther,
    `Do you have any medication allergies or intolerances?
    Include any allergies to food, dyes, prescription or over-the-counter medicines (e.g., antibiotics,
      allergy medications), herbs, vitamins, supplements, or anything else.`,
  ],
  [
    fieldId.medicalConditions,
    `Do you have any medical conditions, a history of prior surgeries, or anything else you need to tell your doctor?`,
  ],
  [fieldId.idImageFile, "Upload a picture of your government ID"],
  [fieldId.selfieImageFile, "Upload a selfie"],
]);

export const get_ = (map: Map<string, string>, key: string): string => {
  return map.get(key) || "";
};

const yupString = Yup.string().required("Required");
const yupBoolean = Yup.boolean().nullable().required("Required");
const yupZip = yupString.matches(/^\d{5}(-\d{4})?$/, "Invalid zip code format");
const yupImage = Yup.object()
  .shape({ base64: Yup.string(), name: Yup.string() })
  .nullable()
  .required("Required")
  .test(
    "fileFormat",
    "Unsupported Format. Use png/jpeg/jpg",
    (value?: FileBase64) => value && SUPPORTED_FILE_TYPES.includes(value.type)
  )
  .test(
    "fileMimeType",
    "Unsupported Mime Type. Use png/jpeg/jpg",
    (value?: FileBase64) =>
      value && !(value.error === FileFormatError.invalidFileType)
  )
  .test(
    "fileSize",
    "File size needs to be less than 10MB",
    (value?: FileBase64) =>
      value &&
      !(
        value.size > backend.SUBMIT_MAX_INDIVIDUAL_FILE_SIZE ||
        value.error === FileBase64Error.compressionFailedTooLarge
      )
  )
  .test(
    "fileError",
    "Invalid file. The file must be under 10MB and the format should png/jpeg/jpg.",
    (value?: FileBase64) =>
      value &&
      !(
        value.error === FileBase64Error.compressionFailedUnknown ||
        value.base64.length < 10
      )
  );

export const getQuestionContents = ({
  editableEmail,
}: {
  editableEmail: boolean;
}): QuestionContent[] => [
  {
    schema: null,
    initialValues: {},
    component: (
      <>
        <h2 className="h2">Prescription Questionnaire</h2>
        <p className="p">
          You are almost there! Please fill in this quick 3-minute
          questionnaire. Once approved, we can ship your order as soon as
          possible.
          <br />
          <br />
          The prescription required for the Abbott FreeStyle Libre sensor is
          handled by our Pharmacy partner, Truepill. Your responses are stored
          securely and are accessible only to an assigned medical practitioner,
          who will review them.
          <br />
          <br />
          If you have any questions, get in touch via hello@veri.co. We&lsquo;re
          happy to help!
        </p>
      </>
    ),
  },
  {
    schema: Yup.object().shape({
      [fieldId.termsOfUse]: Yup.boolean().oneOf([true], "Required"),
    }),
    initialValues: {
      [fieldId.termsOfUse]: false,
    },
    component: (
      <>
        <p className="p">
          Agree to the{" "}
          <a
            className="link"
            href="https://veri.co/terms?doc=terms"
            target="_blank"
            rel="noreferrer noopener"
          >
            Terms of Use
          </a>{" "}
          and{" "}
          <a
            className="link"
            href="https://veri.co/terms?doc=privacy-website"
            target="_blank"
            rel="noreferrer noopener"
          >
            Privacy Policy
          </a>{" "}
          to continue:
        </p>
        <FormikCheckBox
          name={fieldId.termsOfUse}
          label="I have read and agree to Terms of Use and Privacy Policy"
        />
      </>
    ),
  },
  {
    schema: Yup.object().shape({
      [fieldId.email]: yupString.email("Enter valid email"),
      [fieldId.firstName]: yupString,
      [fieldId.lastName]: yupString,
      [fieldId.dateOfBirth]: Yup.date()
        .max(new Date(2010, 0), "Mistyped - invalid year (mm/dd/yyyy)")
        .min(new Date(1930, 0), "Mistyped - invalid year (mm/dd/yyyy)")
        .nullable()
        .required("Required"),
      [fieldId.phone]: yupString.matches(
        /^\d{3} \d{3} \d{4}$/,
        "Required format (XXX XXX XXXX)"
      ),
      [fieldId.homeZip]: yupZip,
    }),
    initialValues: {
      [fieldId.email]: "",
      [fieldId.firstName]: "",
      [fieldId.lastName]: "",
      [fieldId.dateOfBirth]: null,
      [fieldId.phone]: "",
      [fieldId.homeZip]: "",
    },
    component: (
      <>
        <FormikTextInput
          name={fieldId.firstName}
          label={get_(contentLabelMap, fieldId.firstName)}
          infoText="Same that appears on your government ID."
        />
        <FormikTextInput
          name={fieldId.lastName}
          label={get_(contentLabelMap, fieldId.lastName)}
          infoText="Same that appears on your government ID."
        />
        <FormikTextInput
          name={fieldId.email}
          disabled={!editableEmail}
          label={get_(contentLabelMap, fieldId.email)}
          infoText={
            editableEmail
              ? "Same email that you used while purchasing the product."
              : ""
          }
        />
        <FormikPhoneInput
          name={fieldId.phone}
          label={get_(contentLabelMap, fieldId.phone)}
          infoText="Format XXX XXX XXXX"
        />
        <FormikDateInput
          name={fieldId.dateOfBirth}
          label={get_(contentLabelMap, fieldId.dateOfBirth)}
          infoText="Month / Day / Year"
        />
        <FormikTextInput
          name={fieldId.homeZip}
          label={get_(contentLabelMap, fieldId.homeZip)}
        />
      </>
    ),
  },
  {
    schema: Yup.object().shape({
      [fieldId.idImageFile]: yupImage,
      [fieldId.selfieImageFile]: yupImage,
    }),
    initialValues: {
      [fieldId.idImageFile]: null,
      [fieldId.selfieImageFile]: null,
    },
    component: (
      <>
        <p className="p">
          Government ID and a selfie are needed for your identification. Please
          ensure that your ID contains your full name, birth date and your
          photo.
          <br />
          <br />
          Make sure your selfie is easily identifiable as you (i.e. no
          sunglasses, glares or other people in the photo).
        </p>
        <FormikFileUpload
          name={fieldId.idImageFile}
          label={get_(contentLabelMap, fieldId.idImageFile)}
        />
        <FormikFileUpload
          name={fieldId.selfieImageFile}
          label={get_(contentLabelMap, fieldId.selfieImageFile)}
          marginBottom={false}
        />
      </>
    ),
  },

  {
    schema: Yup.object().shape({
      [fieldId.sex]: yupString.nullable(),
    }),
    initialValues: {
      [fieldId.sex]: null,
    },
    component: (
      <>
        <FormikRadioInput
          name={fieldId.sex}
          label={get_(contentLabelMap, fieldId.sex)}
          options={[
            { value: "male", label: "Male" },
            { value: "female", label: "Female" },
          ]}
          autoComplete="off"
        />
      </>
    ),
  },
  {
    schema: Yup.object().shape({
      [fieldId.allergicAdhesive]: yupBoolean,
    }),
    initialValues: {
      [fieldId.allergicAdhesive]: null,
    },
    component: (
      <>
        <FormikTrueFalse
          name={fieldId.allergicAdhesive}
          label={get_(contentLabelMap, fieldId.allergicAdhesive)}
          autoComplete="off"
        />
      </>
    ),
  },
  {
    schema: Yup.object().shape({
      [fieldId.diabetic]: yupBoolean,
      [fieldId.insulin]: Yup.boolean().when(fieldId.diabetic, {
        is: true,
        then: yupBoolean,
        otherwise: Yup.boolean().nullable(),
      }),
      [fieldId.highA1C]: Yup.boolean().when(fieldId.insulin, {
        is: false,
        then: yupBoolean,
        otherwise: Yup.boolean().nullable(),
      }),
      [fieldId.fastingGlc]: Yup.boolean().when(fieldId.highA1C, {
        is: false,
        then: yupBoolean,
        otherwise: Yup.boolean().nullable(),
      }),
    }),
    initialValues: {
      [fieldId.diabetic]: null,
      [fieldId.insulin]: null,
      [fieldId.highA1C]: null,
      [fieldId.fastingGlc]: null,
    },
    component: (
      <>
        <FormikTrueFalse
          name={fieldId.diabetic}
          label={get_(contentLabelMap, fieldId.diabetic)}
          autoComplete="off"
        />
        <FormikTrueFalse
          name={fieldId.insulin}
          label={get_(contentLabelMap, fieldId.insulin)}
          autoComplete="off"
          isDisabled={form => form.values[fieldId.diabetic] === false}
          isHidden={form =>
            form.values[fieldId.diabetic] === null ||
            form.values[fieldId.diabetic] === false
          }
        />
        <FormikTrueFalse
          name={fieldId.highA1C}
          label={get_(contentLabelMap, fieldId.highA1C)}
          autoComplete="off"
          isDisabled={form => form.values[fieldId.insulin] === true}
          isHidden={form =>
            form.values[fieldId.insulin] === null ||
            form.values[fieldId.insulin] === true
          }
        />
        <FormikTrueFalse
          name={fieldId.fastingGlc}
          label={get_(contentLabelMap, fieldId.fastingGlc)}
          autoComplete="off"
          isDisabled={form => form.values[fieldId.highA1C] === true}
          isHidden={form =>
            form.values[fieldId.highA1C] === null ||
            form.values[fieldId.highA1C] === true
          }
        />
      </>
    ),
  },
  {
    schema: Yup.object().shape({
      [fieldId.prescriptions]: yupBoolean,
      [fieldId.prescriptionsList]: Yup.string().when(fieldId.prescriptions, {
        is: false,
        then: yupString,
        otherwise: Yup.string(),
      }),
    }),
    initialValues: {
      [fieldId.prescriptions]: false,
      [fieldId.prescriptionsList]: "",
    },
    component: (
      <>
        <p className="p">
          Please list all of your current medicines, vitamins, and dietary
          supplements. Include any medicines (e.g., Lipitor, Zyrtec, ibuprofen),
          herbs, vitamins, or dietary supplements that you have taken in the
          past 2 weeks, even if you are not taking them today.
        </p>
        <FormikCheckBox
          name={fieldId.prescriptions}
          label="I don’t take any medications"
        />
        <FormikTextArea
          name={fieldId.prescriptionsList}
          label={get_(contentLabelMap, fieldId.prescriptionsList)}
          isDisabled={form => form.values[fieldId.prescriptions] === true}
          placeholderDisabled="None"
          autoComplete="off"
          isHidden={form => form.values[fieldId.prescriptions] === true}
        />
      </>
    ),
  },
  {
    schema: Yup.object().shape({
      [fieldId.allergicOther]: yupBoolean,
      [fieldId.allergicOtherList]: Yup.string().when(fieldId.allergicOther, {
        is: true,
        then: yupString,
        otherwise: Yup.string(),
      }),
    }),
    initialValues: {
      [fieldId.allergicOther]: null,
      [fieldId.allergicOtherList]: "",
    },
    component: (
      <>
        <FormikTrueFalse
          name={fieldId.allergicOther}
          label={get_(contentLabelMap, fieldId.allergicOther)}
          autoComplete="off"
        />
        <FormikTextArea
          name={fieldId.allergicOtherList}
          label={get_(contentLabelMap, fieldId.allergicOtherList)}
          isDisabled={form => form.values[fieldId.allergicOther] === false}
          placeholderDisabled="None"
          aria-placeholder="Please list what you are allergic to and the reaction that each
          allergy causes."
          placeholder="Please list what you are allergic to and the reaction that each
          allergy causes."
          autoComplete="off"
          isHidden={form =>
            form.values[fieldId.allergicOther] === null ||
            form.values[fieldId.allergicOther] === false
          }
        />
      </>
    ),
  },
  {
    schema: Yup.object().shape({
      [fieldId.medicalConditions]: yupBoolean,
      [fieldId.medicalConditionsList]: Yup.string().when(
        fieldId.medicalConditions,
        {
          is: true,
          then: yupString,
          otherwise: Yup.string(),
        }
      ),
    }),
    initialValues: {
      [fieldId.medicalConditions]: null,
      [fieldId.medicalConditionsList]: "",
    },
    component: (
      <>
        <FormikTrueFalse
          name={fieldId.medicalConditions}
          label={get_(contentLabelMap, fieldId.medicalConditions)}
          autoComplete="off"
        />

        <FormikTextArea
          name={fieldId.medicalConditionsList}
          label={get_(contentLabelMap, fieldId.medicalConditionsList)}
          isDisabled={form => form.values[fieldId.medicalConditions] === false}
          placeholderDisabled="None"
          placeholder="Please list your medical condition(s), any prior surgeries, or
          other message to your doctor."
          aria-placeholder="Please list your medical condition(s), any prior surgeries, or
          other message to your doctor."
          autoComplete="off"
          isHidden={form =>
            form.values[fieldId.medicalConditions] === null ||
            form.values[fieldId.medicalConditions] === false
          }
        />
      </>
    ),
  },
  {
    schema: Yup.object().shape({
      [fieldId.state]: yupString.test(
        "usStateAllowsAsyncConsults",
        "Unfortunately, we are currently not able to ship to your selected state due to local regulations. Please select another shipping address or contact care@veri.co for help.",
        value => !TEMPORARILY_UNSUPPORTED_STATES.includes(String(value))
      ),
      [fieldId.shippingZip]: yupZip,
      [fieldId.city]: yupString,
      [fieldId.streetAddress1]: yupString,
      [fieldId.streetAddress2]: Yup.string(),
    }),
    initialValues: {
      [fieldId.state]: "",
      [fieldId.shippingZip]: "",
      [fieldId.city]: "",
      [fieldId.streetAddress1]: "",
      [fieldId.streetAddress2]: "",
    },
    component: (
      <>
        <p className="p">Shipping information</p>
        <FormikTextInput
          name={fieldId.streetAddress1}
          label={get_(contentLabelMap, fieldId.streetAddress1)}
        />
        <FormikTextInput
          name={fieldId.streetAddress2}
          label={get_(contentLabelMap, fieldId.streetAddress2)}
        />
        <FormikTextInput
          name={fieldId.shippingZip}
          label={get_(contentLabelMap, fieldId.shippingZip)}
        />
        <FormikTextInput
          name={fieldId.city}
          label={get_(contentLabelMap, fieldId.city)}
        />
        <FormikStateInput
          name={fieldId.state}
          label={get_(contentLabelMap, fieldId.state)}
        />
      </>
    ),
  },

  {
    schema: null,
    initialValues: {},
    component: (
      <>
        <QuestionnaireSummary />
      </>
    ),
  },
  {
    schema: null,
    initialValues: {},
    component: <QuestionnaireSubmitted />,
  },
];
