import { useEffect } from "react";
import { Field, FieldProps, FormikProps } from "formik";

type ExtraProps = {
  name: string;
  isDisabled?: (formik: FormikProps<any>) => boolean;
  resetOnDisable?: boolean;
  isHidden?: (formik: FormikProps<any>) => boolean;
  resetOnHide?: boolean;
};

export const formikConnect = <Props,>(
  Component: React.ComponentType<Props & ExtraProps>
) => {
  const cmp = ({
    name,
    isDisabled = () => false,
    resetOnDisable = true,
    isHidden = () => false,
    resetOnHide = true,
    ...rest
  }: Omit<Props & ExtraProps, "value">) => (
    /** Defaults everything to input type */
    <Field name={name} type="input">
      {({ field, meta, form }: FieldProps) => {
        const disabled = isDisabled(form);
        const hidden = isHidden(form);

        useEffect(() => {
          if (disabled && resetOnDisable)
            form.setFieldValue(name, form.initialValues[name], false);
        }, [disabled]);

        useEffect(() => {
          if (hidden && resetOnHide)
            form.setFieldValue(name, form.initialValues[name], false);
        }, [hidden]);

        return (
          // @ts-ignore
          <Component
            error={meta.error}
            touched={meta.touched}
            formik={form}
            disabled={disabled}
            hidden={hidden}
            {...field}
            {...rest}
          />
        );
      }}
    </Field>
  );

  return Object.assign(cmp, { displayName: getDisplayName(Component) });
};

const getDisplayName = (WrappedComponent: React.ComponentType<any>) =>
  WrappedComponent.displayName || WrappedComponent.name || "Component";
