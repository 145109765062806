import { RegionDropdown } from "react-country-region-selector";

import _LabeledInputBody from "./_LabeledInputBody";
import { LabeledInputProps } from "./common";

type Props = LabeledInputProps<string>;

// blacklist army and outlying areas
const blacklist = {
  US: ["AA", "AE", "AP", "VI", "UM", "PR", "MP", "GU", "AS"],
} as const;

const LabeledStateInput = ({
  id,
  label,
  infoText,
  error,
  touched,
  onChange,
  value,
  name,
}: Props) => {
  const val = value as string;
  return (
    <_LabeledInputBody
      label={label}
      infoText={infoText}
      error={error}
      touched={!!touched}
      labelHmtlFor={id}
    >
      <RegionDropdown
        name={name}
        country="United States"
        valueType="short"
        // @ts-ignore
        blacklist={blacklist}
        value={val}
        classes="shadow appearance-none w-full h-10 px-3 text-base placeholder-gray-300 border rounded-lg"
        onChange={newValue =>
          onChange &&
          onChange({
            target: { value: newValue, name },
          })
        }
      />
    </_LabeledInputBody>
  );
};

export default LabeledStateInput;
