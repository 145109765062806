import { ComponentProps } from "react";
import classNames from "classnames";

import _LabeledInputBody from "./_LabeledInputBody";
import { LabeledInputProps } from "./common";

type Props = LabeledInputProps<string> & ComponentProps<"input">;

const LabeledTextInput = ({
  id,
  label,
  infoText,
  error,
  touched,
  ...rest
}: Props) => {
  return (
    <_LabeledInputBody
      label={label}
      infoText={infoText}
      error={error}
      touched={!!touched}
      labelHmtlFor={id}
    >
      <input
        {...rest}
        id={id}
        className={classNames("input-field", {
          "border-red-700": touched && error,
        })}
      />
    </_LabeledInputBody>
  );
};

export default LabeledTextInput;
