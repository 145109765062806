import classNames from "classnames";

type Props = {
  children: React.ReactNode;
  error?: string;
  labelHmtlFor?: string; // htmlFor
  touched: boolean;
  /** Whether the label and input should be on the same horizontal line. Defaults to true. */
  oneLine?: boolean;
  /** If true, label takes 2/3 of the space. Defaults to false, which means 1/3 of the space. */
  longLabel?: boolean;
  /** Centers the error text instead of the default align-left */
  centerErrorText?: boolean;
  /** Label text */
  label?: string;
  /** The descriptive info text under the info text. */
  infoText?: string;
  /** Adds the default margin bottom. Defaults to true. */
  marginBottom?: boolean;
};

const _LabeledInputBody = ({
  children,
  label,
  infoText,
  error,
  labelHmtlFor,
  touched,
  oneLine = true,
  longLabel,
  centerErrorText = false,
  marginBottom = true,
}: Props) => {
  const showError = touched && error;
  const extraText = (touched && error) || infoText || null;

  return (
    <div className={classNames({ "mb-6": marginBottom })}>
      <div
        className={classNames("p-primary  mb-2", {
          "md:flex md:items-center": oneLine,
        })}
      >
        {label && (
          <div
            className={classNames("mb-2  pr-6", {
              "md:w-1/3": oneLine && !longLabel && label,
              "md:w-2/3": oneLine && longLabel && label,
            })}
          >
            <label className="font-medium" htmlFor={labelHmtlFor}>
              {label}
            </label>
          </div>
        )}
        <div
          className={classNames("flex flex-col", {
            "md:flex-grow": oneLine && label,
            "md:w-1/3": oneLine && longLabel && label,
            "md:w-2/3": oneLine && !longLabel && label,
          })}
        >
          {children}
          <span
            className={classNames("text-s ml-2 mt-1", {
              "text-gray-600": !showError,
              "text-red-600": showError,
              "mb-4": !extraText,
              "text-center": showError && centerErrorText,
            })}
          >
            {extraText}
          </span>
        </div>
      </div>
    </div>
  );
};

export default _LabeledInputBody;
