import { ComponentProps } from "react";

import LabeledRadioInput from "./LabeledRadioInput";

type Props = Omit<ComponentProps<typeof LabeledRadioInput>, "options">;

const options = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const LabeledTrueFalse = (props: Props) => {
  return <LabeledRadioInput options={options} {...props} />;
};

export default LabeledTrueFalse;
