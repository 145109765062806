import { ComponentProps } from "react";
import classNames from "classnames";

import _LabeledInputBody from "./_LabeledInputBody";
import { LabeledInputProps } from "./common";

type Props = LabeledInputProps<string> &
  ComponentProps<"textarea"> & {
    placeholderDisabled?: string;
  };

const LabeledTextArea = ({
  id,
  label,
  infoText,
  error,
  touched,
  disabled,
  placeholder,
  placeholderDisabled,
  ...rest
}: Props) => {
  return (
    <_LabeledInputBody
      label={label}
      infoText={infoText}
      error={error}
      touched={!!touched}
      labelHmtlFor={id}
      oneLine={false}
    >
      <textarea
        {...rest}
        id={id}
        className={classNames("textarea", {
          "border-red-700": touched && error,
        })}
        disabled={disabled}
        placeholder={disabled ? placeholderDisabled : placeholder}
      />
    </_LabeledInputBody>
  );
};

export default LabeledTextArea;
