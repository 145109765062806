import React from "react";
import Loader from "react-loader-spinner";

type Props = {
  caption?: string;
};

const LoadingView = ({ caption }: Props) => (
  <div className="h-screen ">
    <div className="flex flex-col justify-center items-center h-1/2">
      <Loader
        type="Oval"
        color="#389be2"
        height={50}
        width={50}
        timeout={60000} //60 s
      />
      <div className="h-16" />
      {caption && <p className="p">{caption}</p>}
    </div>
  </div>
);

export default LoadingView;
