import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import companyLogo from "./assets/logo.png";
import QuestionnairePage from "./pages/questionnaire/Questionnaire.page";

const App = () => {
  return (
    <div className="h-screen flex-col items-center justify-center max-h-1/5">
      <div className="flex h-1/7 justify-center  xl:mt-16">
        <img className="h-9 m-8" alt="logo" src={companyLogo} />
      </div>
      <div className="flex flex-col justify-center max-h-2/5">
        <Router>
          <div className="flex justify-center align-middle">
            <Switch>
              <Route path="/questionnaire">
                <QuestionnairePage />
              </Route>
              <Route>
                <div className="flex flex-col p-10 pt-0 w-full md:max-w-2xl md:w-2/3 justify-center h-2/3">
                  <h2 className="h2">Prescription questionnaire</h2>
                  <p className="p">
                    You should receive a link to your prescription questionnaire
                    by email.
                    <br />
                    Contact care@veri.co in case you did not did not receive any
                    email.
                  </p>
                </div>
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
    </div>
  );
};

export default App;
