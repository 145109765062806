type Props = {
  progress: number; // [0,1]
};

const ProgressBar = ({ progress }: Props) => (
  <>
    <p className="p-primary text-center items-center mb-4">
      {`${(progress * 100).toFixed()} % complete`}
    </p>
    <div className="relative  pt-1 h-7">
      <div className="overflow-hidden h-2 flex rounded bg-progressBg">
        <div
          style={{
            width: `${(progress * 100).toFixed()}%`,
            transition: "width 0.3s",
          }}
          className="flex bg-progressDone transition"
        />
      </div>
    </div>
  </>
);

export default ProgressBar;
