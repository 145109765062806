import classNames from "classnames";
import { useFormikContext } from "formik";

type Props = {
  step: number;
  onBack: () => void;
  isFinalStep: boolean;
  formReady: boolean;
};

const SubmitBtn = ({ step, onBack, isFinalStep, formReady }: Props) => {
  const { isValid, dirty } = useFormikContext();

  if (step === 0) {
    return (
      <div className="flex justify-center">
        <button className="btn-primary">Start</button>
      </div>
    );
  }

  const valid = isValid && dirty;

  return (
    <div className="flex justify-around flex-row-reverse">
      {!formReady && (
        <>
          <button
            className={classNames("", {
              "btn-primary": valid,
              "btn-disabled": !valid,
              "w-40 xl:w-48": isFinalStep,
            })}
            type="submit"
          >
            {isFinalStep ? "Submit" : "Next"}
          </button>

          <button
            className={classNames("btn-secondary mr-4", {
              "w-20 xl:w-28": isFinalStep,
            })}
            onClick={onBack}
            type="button"
          >
            Back
          </button>
        </>
      )}
    </div>
  );
};

export default SubmitBtn;
