import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useIdleTimer } from "react-idle-timer";
import Modal from "react-modal";

type Props = {
  maxIdleDuration?: number; // ms
  warningDuration?: number;
};

/** Refresh the page automatically if idle for enough long.
 * Shows a modal to confirm the result.
 */
const IdleModal = (
  { maxIdleDuration = 30 * 60 * 1000, warningDuration = 60 * 1000 }: Props,
  ref: React.Ref<{ isVisible: () => boolean }>
) => {
  const [showExpirationModal, setShowExpirationModal] = useState(false);
  const expirationTimeout = useRef<NodeJS.Timeout>();

  useImperativeHandle(ref, () => ({
    isVisible: () => showExpirationModal,
  }));

  // show warning modal before refreshing the page
  const handleIdle = () => {
    setShowExpirationModal(true);
    expirationTimeout.current = setTimeout(
      () => window.location.reload(),
      warningDuration
    );
  };

  // prevent refreshing the page if user continues
  const onModalContinue = () => {
    expirationTimeout.current && clearTimeout(expirationTimeout.current);
    setShowExpirationModal(false);
  };

  const onModalClose = () => {
    setShowExpirationModal(false);
  };

  useIdleTimer({
    timeout: maxIdleDuration - warningDuration,
    onIdle: handleIdle,
    onActive: () => null,
    onAction: () => null,
    debounce: 500,
  });

  return (
    <Modal
      isOpen={showExpirationModal}
      onRequestClose={onModalClose}
      contentLabel="Selected Option"
      style={customStyles}
      ariaHideApp={false}
    >
      <p className="p">
        The page will expire soon due to inactivity. Please press
        &quot;Continue&quot; if you want to continue filling the form.
      </p>
      <div className="flex justify-center">
        <button className="btn-secondary" onClick={onModalContinue}>
          Continue
        </button>
      </div>
    </Modal>
  );
};

const customStyles = {
  content: {
    top: "20%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #d9d8d4",
  },
  overlay: {
    backgroundColor: "rgba(255, 255, 255, 0.75)",
  },
};

export default forwardRef(IdleModal);
