import { createContext, useReducer } from "react";

type State = {
  isAuthenticated: boolean;
  email: string;
};

type Action =
  | { type: "setIsAuthenticated"; value: boolean }
  | { type: "setEmail"; value: string };

const initialState: State = {
  isAuthenticated: true,
  email: "",
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "setIsAuthenticated":
      return { ...state, isAuthenticated: action.value };
    case "setEmail":
      return { ...state, email: action.value };
  }
};

const SharedCx = createContext<[State, React.Dispatch<Action>]>(undefined!);

/** HOC that provides shared global context. */
export const SharedCxProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SharedCx.Provider value={[state, dispatch]}>{children}</SharedCx.Provider>
  );
};

export default SharedCx;
