import { useFormikContext } from "formik";

const QuestionnaireSubmitted = () => {
  const { values } = useFormikContext();

  const vals = values as Record<string, any>;

  return (
    <>
      <h2 className="h2">Prescription form submitted successfully!</h2>
      <p className="p">
        Your prescription will soon be reviewed by a doctor. We will send you an
        update to the provided email address at {vals.email}.
      </p>
    </>
  );
};

export default QuestionnaireSubmitted;
