import { ComponentProps } from "react";
import DatePicker from "react-datepicker";

import _LabeledInputBody from "./_LabeledInputBody";
import { LabeledInputProps } from "./common";

type Props = LabeledInputProps<Date> &
  Omit<ComponentProps<typeof DatePicker>, "onChange">;

const LabeledDateInput = ({
  name,
  value,
  label,
  infoText,
  error,
  touched,
  onChange,
  ...rest
}: Props) => {
  return (
    <_LabeledInputBody
      label={label}
      infoText={infoText}
      error={error}
      touched={!!touched}
    >
      <DatePicker
        {...rest}
        name={name}
        closeOnScroll={false}
        selected={value}
        onChange={date =>
          onChange &&
          onChange({
            target: { value: date as Date, name },
          })
        }
        className="input-field"
        wrapperClassName="w-full"
        dateFormat="MM/dd/yyyy"
        placeholderText="mm/dd/yyyy"
      />
    </_LabeledInputBody>
  );
};

export default LabeledDateInput;
