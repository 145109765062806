type Props = {
  title: string;
  body: string;
};

const ErrorView = ({ title, body }: Props) => (
  <>
    <h2 className="h2">{title}</h2>
    <p className="p">{body}</p>
  </>
);

export const errorView = {
  alreadyFilled: (
    <ErrorView
      title="The prescription form was already filled and submitted!"
      body="Your prescription now is waiting to be reviewed by a doctor. We will
 keep you informed about the progress by email."
    />
  ),
  invalidToken: (
    <ErrorView
      title="Your link to the prescription form has expired!"
      body="Contact care@veri.co in case you haven't already submitted
 the prescription from."
    />
  ),
  submitFailed: (
    <ErrorView
      title="Something went wrong during the submission!"
      body="Contact care@veri.co for further assistance. You can also try
again with a different device."
    />
  ),
};

export default ErrorView;
