import { useContext, useEffect, useRef, useState } from "react";
import React from "react";
import { useBeforeunload } from "react-beforeunload";
import { Redirect } from "react-router";
import { useLocation } from "react-router-dom";
import { Form, Formik, FormikHelpers } from "formik";

import CheckMark from "components/CheckMark";
import IdleModal from "components/IdleModal";
import ProgressBar from "components/ProgressBar";
import SharedCx from "context/SharedCx";
import backend, { SubmissionType, UserState } from "services/backend";
import { errorView } from "./ErrorView";
import LoadingView from "./LoadingView";
import { getQuestionContents } from "./questionContents";
import SubmitBtn from "./SubmitBtn";

/** There are 3 different setups how the user can access the page:
 * 1 . userId and userCode exists
 *     - The access is verified immediately when entered into a page
 *     - No personal information in URL
 *     - Generating userId might take ~20s after the payment because of the
 *       webhooks. So this cannot be used immediately after the payment.
 * 2. userCode exists
 *    - The access is verified only when the form is submitted
 *    - The user must write their email address that matches the userCode
 * 3. userCode and email (base64) exists
 *    - The access is verified when the form submitted
 *    - Personal information in the URL
 */
const QuestionnairePage = () => {
  const urlParams = new URLSearchParams(useLocation().search);

  const userId = urlParams.get("u") || ""; // User ID
  const userCode = urlParams.get("c") || ""; // User code
  const emailUriEncodedBase64 = urlParams.get("eb") || ""; // Email base64 urlEncoded
  const ignoreExisting = urlParams.get("ie") === "1"; // Ignore existing prescription

  const submissionType = userId
    ? SubmissionType.accessCodeAndUserId
    : SubmissionType.accessCode;

  const editableEmail =
    submissionType === SubmissionType.accessCode &&
    !emailUriEncodedBase64.length;

  const questionContents = getQuestionContents({ editableEmail });

  const initialValues = questionContents.reduce(
    (combined, current) => ({ ...combined, ...current.initialValues }),
    {}
  );

  const [step, setStep] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [validPrescriptionExists, setValidPrescriptionExists] =
    useState<boolean>(false);
  const [prescriptionExpiry, setPrescriptionExpiry] = useState<string>("");
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);

  const [userState, setUserState] = useState(UserState.valid);
  const [sharedCx, sharedCxDispatch] = useContext(SharedCx);

  const idleModalRef = useRef<React.ElementRef<typeof IdleModal>>(null);

  const stepAmount = questionContents.length;
  const isSubmitStep = step === stepAmount - 2;

  // verify the user code and hide the loading indicator
  useEffect(() => {
    const fetchStatus = async () => {
      if (submissionType === SubmissionType.accessCodeAndUserId) {
        const { email, state, prescriptionValidUntil } =
          await backend.verifyUser({ userId, userCode });
        sharedCxDispatch({
          type: "setEmail",
          value: email,
        });
        setUserState(state);
        if (
          prescriptionValidUntil &&
          new Date().toISOString() < prescriptionValidUntil
        ) {
          setValidPrescriptionExists(true);
          setPrescriptionExpiry(prescriptionValidUntil);
        }
      } else if (emailUriEncodedBase64) {
        try {
          const email = atob(decodeURIComponent(emailUriEncodedBase64));
          sharedCxDispatch({
            type: "setEmail",
            value: email,
          });
        } catch {
          setUserState(UserState.invalid);
        }
      }
      setIsLoading(false);
    };

    fetchStatus(); // promise ignored on purpose
  }, []);

  // show warning before leaving the page
  useBeforeunload((event: any) => {
    if (
      idleModalRef.current?.isVisible() === false &&
      step > 1 &&
      step < stepAmount - 2
    ) {
      event.preventDefault();
    }
  });

  // submits the form
  const handleSubmit = async (values: any, resetForm: () => void) => {
    setIsSubmittingForm(true);
    setIsLoading(true);

    try {
      await backend.submit({ userId, userCode }, values, submissionType);
      setStep(step + 1);
    } catch (error) {
      setIsSubmitError(true);
    }

    resetForm();
    setIsLoading(false);
    setIsSubmittingForm(false);
  };

  // is called when pressed formik has verified the input and calls onSubmit
  const onNext = async (values: any, actions: FormikHelpers<any>) => {
    if (isSubmitStep) {
      // TODO: why the settimeout is needed?
      setTimeout(async () => {
        await handleSubmit(values, actions.resetForm);
        actions.setSubmitting(false);
      }, 100);
    } else {
      setStep(step + 1);

      // marks that all fields to be untouched again,
      // since by default fields becomes touched when formik's onSubmit is called
      for (const k in initialValues) actions.setFieldTouched(k, false, false);
    }
  };

  const onBack = () => {
    setStep(Math.max(step - 1, 0));
  };

  // pick the questions
  const questionContent = questionContents[step];
  const schema = questionContent.schema;
  let viewContent = questionContent.component;

  const noErrors = userState === UserState.valid && !isSubmitError;

  // set error views if needed
  if (!noErrors) {
    if (isSubmitError) viewContent = errorView.submitFailed;
    else if (userState === UserState.used)
      viewContent = errorView.alreadyFilled;
    else viewContent = errorView.invalidToken;
  }

  const progressBarProgress = Math.min(
    Math.max(0, (step - 1) / (stepAmount - 3)),
    1
  );
  if (!userCode) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  if (isLoading) {
    return (
      <div className="flex flex-col p-10 pt-0 w-full md:max-w-2xl md:w-2/3 justify-center h-2/3">
        <LoadingView
          caption={
            isSubmittingForm
              ? "Submitting the form ... This may take a few minutes."
              : undefined
          }
        />
        <IdleModal ref={idleModalRef} />
      </div>
    );
  }

  if (validPrescriptionExists && !ignoreExisting) {
    return (
      <div className="flex flex-col p-10 pt-0 w-full md:max-w-2xl md:w-2/3 justify-center h-2/3">
        <CheckMark
          caption={`Your prescription is still valid until ${new Date(
            prescriptionExpiry
          ).toLocaleDateString()}. You're all set, now sit back, relax and wait for your next order to arrive! 🎉`}
        />
        <IdleModal ref={idleModalRef} />
      </div>
    );
  }

  return (
    <div className="flex flex-col p-10 pt-0 w-full md:max-w-2xl md:w-2/3 justify-center h-2/3">
      <Formik
        initialValues={{
          ...initialValues,
          email: sharedCx.email,
          termsOfUse: false,
        }}
        validateOnMount={true}
        onSubmit={onNext}
        validationSchema={schema}
      >
        <div className="h-1/2">
          <div className="mb-5 ">
            {noErrors && <ProgressBar progress={progressBarProgress} />}
          </div>
          <Form>
            {viewContent}
            <div className="h-10" />
            {noErrors && (
              <SubmitBtn
                step={step}
                onBack={onBack}
                formReady={step + 1 === stepAmount}
                isFinalStep={isSubmitStep}
              />
            )}
          </Form>
        </div>
      </Formik>
      <IdleModal ref={idleModalRef} />
    </div>
  );
};

export default QuestionnairePage;
