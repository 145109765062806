import axios from "axios";
import { cloneDeep } from "lodash";
import moment from "moment";

import { QuestionFields } from "pages/questionnaire/questionContents";

export enum UserState {
  valid = 1, // start from 1 to avoid errors with truthy values
  used,
  invalid,
  expired,
  discarded,
}

// maps backend return value to frontend UserState
const userStateMap = new Map<string, UserState>([
  ["valid", UserState.valid],
  ["used", UserState.used],
  ["invalid", UserState.invalid],
  ["expired", UserState.expired],
  ["discarded", UserState.discarded],
]);

type User = {
  userId?: string;
  userCode: string;
};

/** Note: The values are defined in the Backend */
export enum SubmissionType {
  accessCode = "accessCode",
  accessCodeAndUserId = "accessCodeAndUserId",
}

/** Verifies the user access code and returns the email */
const verifyUser = async ({ userId, userCode }: User) => {
  const result = await axios.post(
    `${process.env.REACT_APP_PRESCRIPTION_BACKEND_URL}/verify`,
    {
      userId: userId,
      code: userCode,
    }
  );
  const parsedState = result.data.status as string;
  const email = (result.data.email as string) || "";
  const state = userStateMap.get(parsedState) ?? UserState.invalid;

  return {
    email,
    state,
    prescriptionValidUntil: result.data.prescriptionValidUntil
      ? String(result.data.prescriptionValidUntil)
      : null,
  };
};

/** Submits the form data */
const SUBMIT_MAX_INDIVIDUAL_FILE_SIZE = 14.5e6; // ~30 MB limit on Truepill side => 2x pictures => ~15 MB per image
const submit = async (
  { userId, userCode }: User,
  values: QuestionFields,
  submissionType: SubmissionType
) => {
  const vals = cloneDeep(values);

  vals.idImageFile = {
    filename: vals.idImageFile.name,
    base64: vals.idImageFile.base64,
  };
  vals.selfieImageFile = {
    filename: vals.selfieImageFile.name,
    base64: vals.selfieImageFile.base64,
  };

  vals.dateOfBirth = moment(vals.dateOfBirth).format("YYYY-MM-DD");
  vals.allergicOtherList = vals.allergicOtherList || "N/A";
  vals.prescriptionsList = vals.prescriptionsList || "N/A";
  vals.medicalConditionsList = vals.medicalConditionsList || "N/A";
  vals.phone = vals.phone.split(" ").join("-");

  await axios.post(`${process.env.REACT_APP_PRESCRIPTION_BACKEND_URL}/submit`, {
    userId: userId,
    code: userCode,
    submission: vals,
    submissionType,
  });
};

export default { verifyUser, submit, SUBMIT_MAX_INDIVIDUAL_FILE_SIZE };
