import { ComponentProps } from "react";

import _LabeledInputBody from "./_LabeledInputBody";
import { LabeledInputProps } from "./common";

type ValueType = string | number | boolean;

type Props = LabeledInputProps<ValueType> &
  Omit<ComponentProps<"input">, "options" | "onChange" | "value"> & {
    options: { value: ValueType; label: string }[];
  };

const LabeledRadioInput = ({
  name,
  id,
  label,
  infoText,
  error,
  touched,
  onChange,
  value,
  options,
  ...rest
}: Props) => {
  const createOnChange =
    (radioValue: ValueType) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange && event.target.checked) {
        onChange({
          target: { value: radioValue, name },
        });
      }
    };

  if (rest.hidden) {
    return null;
  }

  return (
    <_LabeledInputBody
      label={label}
      infoText={infoText}
      error={error}
      touched={!!touched}
      labelHmtlFor={id}
      longLabel
      centerErrorText
    >
      <div className="flex flex-wrap justify-around">
        {options.map(({ value: v, label: l }) => (
          <label key={l} className="text-gray-700">
            <input
              name={name}
              type="radio"
              // @ts-ignores
              value={v}
              onChange={createOnChange(v)}
              checked={value === v}
              {...rest}
            />
            <span className="ml-1">{l}</span>
          </label>
        ))}
      </div>
    </_LabeledInputBody>
  );
};

export default LabeledRadioInput;
