import React from "react";
import { useFormikContext } from "formik";

import { contentLabelMap, get_ } from "./questionContents";

const stringifyValue = (val: string | Object | any) => {
  if (typeof val === "boolean") return val ? "Yes" : "No";
  if (val instanceof File) return val.name;
  if (val instanceof Date) return val.toLocaleDateString("en-US");
  if (val instanceof Object && val.name) return val.name;
  if (val instanceof Object) return JSON.stringify(val);
  return val;
};

const QuestionnaireSummary = () => {
  const { values } = useFormikContext();

  const vals = values as Record<string, any>;
  const keys = Object.keys(vals);

  return (
    <>
      <p className="p">
        Check that all information is correct before submitting the form.
      </p>
      <div className="flex flex-wrap overflow-scroll h-96 p-2 gap-1">
        {keys.map((key: string) => (
          <React.Fragment key={key}>
            <div className="flex flex-auto items-center">
              <div className="w-1/2">
                <p className="p-primary font-medium">
                  {get_(contentLabelMap, key)}
                </p>
              </div>
              <div className="max-w-1/3 w-1/2">
                <p className="p-primary text-right">
                  {stringifyValue(vals[key])}
                </p>
              </div>
            </div>
            <div className="h-3 w-full" />
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default QuestionnaireSummary;
