type Props = {
  caption: string;
};

const CheckMark = ({ caption }: Props) => (
  <>
    <div className="h-screen ">
      <div className="flex flex-col justify-center items-center h-1/2">
        <div
          style={{
            border: "7px solid #78b13f",
            width: "8rem",
            height: "8rem",
          }}
          className="rounded-full relative"
        >
          <div
            style={{
              display: "inline-block",
              position: "absolute",
              transform: "rotate(45deg) translate(-50%, -50%)",
              borderBottom: "7px solid #78b13f",
              borderRight: "7px solid #78b13f",
              height: "2.333rem",
              width: "1.333rem",
              left: "35%",
              top: "45%",
            }}
          />
        </div>
        <div className="h-16" />
        <div className="w-3/4">
          <p className="p text-lg">{caption}</p>
        </div>
      </div>
    </div>
  </>
);

export default CheckMark;
