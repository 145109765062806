import { ComponentProps } from "react";

import _LabeledInputBody from "./_LabeledInputBody";
import { LabeledInputProps } from "./common";

type Props = LabeledInputProps<boolean> & ComponentProps<"input">;

const LabeledCheckBox = ({
  name,
  id,
  label,
  infoText,
  error,
  touched,
  onChange,
  value,
  ...rest
}: Props) => {
  const _onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange && event.target) {
      onChange({
        target: { value: event.target.checked, name },
      });
    }
  };

  return (
    <_LabeledInputBody
      infoText={infoText}
      error={error}
      touched={!!touched}
      labelHmtlFor={id}
      longLabel
    >
      <div className="flex flex-wrap items-center">
        <label id={id} className="ml-3">
          <input
            className="mr-2"
            name={name}
            type="checkbox"
            onChange={_onChange}
            checked={!!value}
            {...rest}
          />
          {label}
        </label>
      </div>
    </_LabeledInputBody>
  );
};

export default LabeledCheckBox;
