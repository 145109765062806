import imageCompression from "browser-image-compression";

import { allowedMimeType, toBase64 } from "utils/file";
import { LabeledInputProps } from "./common";
import LabeledFileInput from "./LabeledFileInput";

export enum FileBase64Error {
  compressionFailedTooLarge = "compressionFailedTooLarge",
  compressionFailedUnknown = "unknown",
}

export enum FileFormatError {
  invalidFileType = "invalidFileType",
}

export type FileBase64 = {
  size: number;
  name: string;
  type: string;
  base64: string;
  error?: FileBase64Error | FileFormatError;
};

type Props = LabeledInputProps<FileBase64>;

const COMPRESS_SIZE_REQUIRED_SIZE = 10e6; // 10 MB
const COMPRESSION_TARGET_SIZE = 3e6;
const COMPRESSION_TARGET_WIDTH = 1920;

const FAILED_COMPRESSION_SIZE = 999; // under 1 kb

const LabeledImageInput = (props: Props) => {
  const preProcessFiles = async (files: FileList) => {
    let file = files[0];
    let error = undefined;

    const correctFileType = await allowedMimeType(file);

    if (!correctFileType) {
      error = FileFormatError.invalidFileType;
    }

    if (file.size > COMPRESS_SIZE_REQUIRED_SIZE) {
      try {
        file = await imageCompression(file, {
          maxSizeMB: COMPRESSION_TARGET_SIZE / 1e6,
          maxWidthOrHeight: COMPRESSION_TARGET_WIDTH,
        });
        if (file.size < FAILED_COMPRESSION_SIZE)
          error = FileBase64Error.compressionFailedTooLarge;
      } catch (err) {
        error = FileBase64Error.compressionFailedUnknown;
      }
    }

    const { size, type, name } = file;
    return {
      size,
      type,
      name,
      base64: await toBase64(file),
      error,
    };
  };

  return (
    <LabeledFileInput
      accept="image/jpeg, image/png, image/jpg"
      preProcessFiles={preProcessFiles}
      noFileSelectedText="(png/jpeg/jpg)"
      {...props}
    />
  );
};

export default LabeledImageInput;
