import { ComponentProps, useRef } from "react";
import { useState } from "react";
import classNames from "classnames";

import _LabeledInputBody from "./_LabeledInputBody";
import { LabeledInputProps } from "./common";

type GenericFileType = {
  name: string;
};

type Props<T> = LabeledInputProps<T> & {
  preProcessFiles: (fileList: FileList) => Promise<T>;
  noFileSelectedText: string;
} & Omit<ComponentProps<"input">, "onChange" | "value">;

const LabeledFileInput = <ProcessedFile extends GenericFileType>({
  name,
  id,
  label,
  infoText,
  error,
  touched,
  onChange,
  value,
  marginBottom,
  preProcessFiles,
  noFileSelectedText,
  ...rest
}: Props<ProcessedFile>) => {
  const [isLoading, setIsLoading] = useState(false);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  // click the hidden file input element to open the file selector
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    hiddenFileInput.current && hiddenFileInput.current.click();
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange && event.target.files) {
      setIsLoading(true);
      const result = await preProcessFiles(event.target.files);
      onChange({
        target: { value: result, name },
      });
      setIsLoading(false);
    }
  };

  const description = isLoading
    ? "Loading..."
    : value?.name
    ? value.name
    : noFileSelectedText;

  return (
    <_LabeledInputBody
      label={label}
      infoText={infoText}
      error={error}
      touched={!!touched}
      labelHmtlFor={id}
      longLabel
      oneLine={false}
      centerErrorText
      marginBottom={marginBottom}
    >
      <div className="flex flex-col">
        <div className="flex justify-center items-center flex-col mt-4">
          <button
            className="bg-gray-100 border-gray-300 border  rounded-md p-2 hover:opacity-80"
            onClick={handleClick}
          >
            Upload file
          </button>
          <input
            style={{ display: "none" }}
            name={name}
            id={id}
            ref={hiddenFileInput}
            type="file"
            accept="image/jpeg, image/png"
            onChange={handleChange}
            {...rest}
          />
          <span className="flex justify-center items-center mt-1">
            <p
              className={classNames("", {
                "text-gray-400 text-sm": !isLoading && !value,
                italic: value,
              })}
            >
              {description}
            </p>
          </span>
        </div>
      </div>
    </_LabeledInputBody>
  );
};

export default LabeledFileInput;
