import { ComponentProps, useState } from "react";
import classNames from "classnames";

import _LabeledInputBody from "./_LabeledInputBody";
import { LabeledInputProps } from "./common";

type Props = LabeledInputProps<string> & ComponentProps<"input">;

const formatPhoneNumber = (input: string) => {
  const [, g1 = "", g2 = "", g3 = ""] =
    input.match(/^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/) || [];

  // the groups might not be length 3
  if (g1.length === 3 && g2.length === 3) return `${g1} ${g2} ${g3}`.trim();
  if (g1.length === 3) return `${g1} ${g2}`.trim();
  return `${g1}`.trim();
};

const LabeledPhoneInput = ({
  id,
  label,
  infoText,
  error,
  touched,
  onChange,
  name,
  ...rest
}: Props) => {
  const [value, setValue] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;

    // only format when adding numbers
    if (newValue.length >= value.length) newValue = formatPhoneNumber(newValue);

    setValue(newValue);

    onChange &&
      onChange({
        target: { value: newValue, name },
      });
  };

  return (
    <_LabeledInputBody
      label={label}
      infoText={infoText}
      error={error}
      touched={!!touched}
      labelHmtlFor={id}
    >
      <input
        type="tel"
        name={name}
        onChange={handleChange}
        id={id}
        className={classNames("input-field", {
          "border-red-700": touched && error,
        })}
        {...rest}
      />
    </_LabeledInputBody>
  );
};

export default LabeledPhoneInput;
