import imageCompression from "browser-image-compression";

import { Expand } from "./type";

/** Returns the file as base64 format */
export const toBase64 = (file: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve((reader.result as string).split(",")[1]);
    reader.onerror = error => reject(error);
  });

export const compressImage = (
  file: File,
  settings: Expand<Parameters<typeof imageCompression>[1]>
): Promise<File> => imageCompression(file, settings);

/* Check type of file by reading first 4 bytes from buffer.
Solution found on stackoverflow: https://stackoverflow.com/a/29672957/10258429
Check list of file signatures https://en.wikipedia.org/wiki/List_of_file_signatures
*/
export const allowedMimeType = async (file: Blob): Promise<boolean> => {
  const buffer = await file.arrayBuffer();
  const arr = new Uint8Array(buffer).subarray(0, 4);
  const header = arr.reduce((prev, curr) => prev + curr.toString(16), "");
  switch (header) {
    // "image/png"
    case "89504e47":
      return true;
    // "image/jpeg"
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
    case "ffd8ffdb":
      return true;
    default:
      return false;
  }
};
